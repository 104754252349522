import { React, useEffect, useState } from "react";
import "../css/Carton.css";
import "../css/Croissance.css";
import getPreferredLanguage from "../utils/getPrefferedLanguage";

const Croissance = ({ scrollToSection, currentIndex, isMobile = false }) => {
  const [titre, setTitre] = useState("MAXIMISER VOTRE POTENTIEL NUMÉRIQUE");
  const [emFading, setEmFading] = useState(
    "Attirez l'attention de vos futures clients en vous démarquant grâce à un site qui correspond à votre image de marque"
  );
  const [fading, setFading] = useState(
    "Il est aujourd'hui nécessaire de trouver les clients là où ils sont, transformez vos visiteurs en clients fidèles en touchant un maximum de prospects grâce à un positionnement web ciblé. Enrichissez votre clientèle avec nos solutions digitales innovantes"
  );

  useEffect(() => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("entering_view");
        } else {
          entry.target.classList.remove("entering_view");
        }
      });
    };

    const handleIntersectMobile = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("entering_view");
        }
      });
    };

    const mobileObserver = new IntersectionObserver(handleIntersectMobile);
    const nonMobileObserver = new IntersectionObserver(handleIntersect);

    const imageWrappers = document.querySelectorAll(".fading");

    if (isMobile) {
      console.log("Setting up mobile observer");
      imageWrappers.forEach((imageWrapper) => {
        mobileObserver.observe(imageWrapper);
      });
    } else {
      console.log("Setting up non-mobile observer");
      imageWrappers.forEach((imageWrapper) => {
        nonMobileObserver.observe(imageWrapper);
      });
    }

    return () => {
      imageWrappers.forEach((imageWrapper) => {
        mobileObserver.unobserve(imageWrapper);
        nonMobileObserver.unobserve(imageWrapper);
      });
    };
  }, [isMobile]);

  const handleClick = () => {
    scrollToSection(currentIndex + 1);
  };

  useEffect(() => {
    if (getPreferredLanguage() !== "fr") {
      setTitre("MAXIMIZE YOUR DIGITAL PRESENCE");
      setEmFading(
        "Grab the attention of your future customers by setting yourself apart with a website that matches your brand image."
      );
      setFading(
        "Today, it's essential to find customers where they are. Turn your visitors into loyal customers by reaching a maximum number of prospects thanks to our targeted web positioning. Enrich your customer base with our user-oriented innovative digital solutions"
      );
    }
  }, []);

  return (
    <section className="Croisssance">
      <div className="croissance carton">
        <div className="wrapper">
          <div className="textSide">
            <h1 className="text">
              <span className="titre">{titre}</span>
            </h1>

            <br />
            <span className="em fading">{emFading}</span>
            <br />
            <span className="fading">{fading}</span>
          </div>
          <div className="image_wrapper">
            <div className="image_size">
              <div className="zoink_effect fading">
                <img src="/img/Yellow.webp" alt="Croissance" />
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="down_wrapper">
            <button className="arrow" onClick={handleClick}></button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Croissance;
