import React, { useEffect, useState } from "react";
import "./App.css";

import Croissance from "./Components/Croissance";
import Simplicite from "./Components/Simplicité";
import Succes from "./Components/Succès";
import Prix from "./Components/Prix";
import Formulaire from "./Components/Formulaire";
import AnimationPanel from "./Components/AnimationPanel";

const Desktop = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 2800);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {!animation && (
        <div>
          <AnimationPanel />
        </div>
      )}
      {animation && (
        <div className="Mobile_Wrapper">
          <Simplicite isMobile={true} />
          <Croissance isMobile={true} />
          <Succes isMobile={true} />
          <Prix isMobile={true} />
          <Formulaire isMobile={true} />
        </div>
      )}
    </div>
  );
};

export default Desktop;
