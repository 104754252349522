import React from "react";
import "../css/AnimationPanel.css";

const AnimationPanel = () => {
  return (
    <section className="AnimationPanel">
      <div className="animation_panel">
        <img src="/Logo_414_Black.png" alt="logo" />
      </div>
    </section>
  );
};

export default AnimationPanel;
