import React, { useRef, useEffect, useState } from "react";
import "../css/Formulaire.css";
import getPreferredLanguage from "../utils/getPrefferedLanguage";

const Formulaire = ({ intoView = false, isMobile = false }) => {
  const [ravis, setRavis] = useState(
    "Nous serions ravis d'entendre parler de vous"
  );
  const [hesiter, setHesiter] = useState(
    "N'hésitez pas à nous contacter si vous souhaitez collaborer avec nous ou simplement discuter de vos envies"
  );
  const [mail, setMail] = useState(
    "Vous n'aimez pas les formulaires ? Envoyez-nous un mail !"
  );
  const [contactUs, setContactUs] = useState("Nous Contacter");
  const [nom, setNom] = useState("Nom");
  const [phone, setPhone] = useState("Téléphone");
  const [company, setCompany] = useState("Entreprise");
  const formulaireRef = useRef(null);
  const [Validate, setValidate] = useState("Valider");

  // eslint-disable-next-line
  const [scrolledPastThreshold, setScrolledPastThreshold] = useState(false);

  /*   useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200;

      if (scrollPosition > threshold) {
        setScrolledPastThreshold(true);
      } else {
        setScrolledPastThreshold(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */

  useEffect(() => {
    const formulaireElement = formulaireRef.current;

    if (intoView) {
      setTimeout(() => {
        formulaireElement.classList.add("zIndexChange");
      }, 1500);
    } else {
      formulaireElement.classList.remove("intoView", "zIndexChange");
    }
  }, [intoView]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch("/api/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert("Nous vous contacterons bientôt");
        e.target.reset();
      } else {
        alert("Error submitting form");
      }
    } catch (error) {
      alert("Error submitting form");
    }
  };

  useEffect(() => {
    if (getPreferredLanguage() !== "fr") {
      setRavis("We'd love to hear from you !");
      setHesiter(
        "Don't hesitate to contact us if you'd like to collaborate with us or simply discuss your ideas"
      );
      setMail("Don't like forms? Send us an e-mail !");
      setContactUs("Contact Us");
      setNom("Name");
      setPhone("Phone");
      setCompany("Company");
      setValidate("Confirm");
    }
  }, []);

  return (
    <section className="Formulaire">
      <div
        className={`formulaire ${intoView ? "intoView" : ""} ${
          isMobile ? "mobile" : ""
        }`}
        ref={formulaireRef}
      >
        <div className="left">
          <h1 className="titre">414dev</h1>
          {!isMobile && (
            <>
              <h1>{ravis}</h1>
              <br />
              <h4>{hesiter}</h4>
            </>
          )}
          <br />
          <h4 className="send_mail">{mail}</h4>
          <br />
          <a href="mailto:414dev.contact@gmail.com?subject=Offre%20de%20partenariat">
            Mail: contact@414dev.com
          </a>

          <h4 className="em">Téléphone : 06 12 30 29 01</h4>
          <br />
          <a href="/CGU_414Dev.pdf">CGU</a>
        </div>
        <div className="form_wrapper">
          <form onSubmit={handleSubmit} autoComplete="on" name="contact">
            <h1>{contactUs}</h1>
            <div className="input-container wrapper1">
              <input
                type="text"
                name="nom"
                id="nom"
                className="input"
                placeholder=" "
                required
              />
              <div className="cut"></div>
              <label htmlFor="nom" className="placeholder">
                {nom}
              </label>
            </div>
            <div className="input-container wrapper2">
              <input
                type="email"
                name="email"
                id="email"
                className="input"
                placeholder=" "
                required
              />
              <div className="cut"></div>
              <label htmlFor="email" className="placeholder">
                Email
              </label>
            </div>
            <div className="input-container wrapper2">
              <input
                type="tel"
                name="téléphone"
                id="téléphone"
                className="input"
                placeholder=" "
              />
              <div className="cut"></div>
              <label htmlFor="téléphone" className="placeholder">
                {phone}
              </label>
            </div>
            <div className="input-container wrapper2">
              <input
                type="text"
                name="entreprise"
                id="entreprise"
                className="input"
                placeholder=" "
              />
              <div className="cut"></div>
              <label htmlFor="entreprise" className="placeholder">
                {company}
              </label>
            </div>
            <div className="input-container wrapper2">
              <input
                type="text"
                name="description"
                id="description"
                className="input"
                placeholder=" "
              />
              <div className="cut"></div>
              <label htmlFor="description" className="placeholder">
                Description
              </label>
            </div>
            <input type="submit" value={Validate} className="submit" />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Formulaire;
