import React, { useEffect, useState, useMemo } from "react";
import "../css/Carton.css";
import "../css/Prix.css";
import getPreferredLanguage from "../utils/getPrefferedLanguage";

const Prix = ({ scrollToSection, currentIndex, isMobile = false }) => {
  const [argumentIndex, setArgumentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [displayedText, setDisplayedText] = useState("");

  const [price, setPrice] = useState("50€");
  const [priceText, setPriceText] = useState(" Par Mois Pour");
  const [sansEngagement, setSansEngagement] = useState(
    "SANS ENGAGEMENT, prix initial à négocier"
  );

  const allArguments = useMemo(
    () =>
      getPreferredLanguage() === "fr"
        ? [
            "Le Maquettage",
            "Le Design",
            "Le Contenu",
            "L'Hébergement",
            "Le SAV",
          ]
        : [
            "The Wireframing",
            "The Design",
            "The Hosting",
            "The Content",
            "The CS",
          ],
    []
  );

  const handleClick = () => {
    scrollToSection(currentIndex + 1);
  };

  useEffect(() => {
    let timer;
    const handleTextUpdate = () => {
      const fullText = allArguments[argumentIndex];
      const updatedText = isDeleting
        ? fullText.substring(0, displayedText.length - 1)
        : fullText.substring(0, displayedText.length + 1);

      setDisplayedText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        timer = setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && updatedText === "") {
        setIsDeleting(false);
        setArgumentIndex((prevIndex) => (prevIndex + 1) % allArguments.length);
      } else {
        timer = setTimeout(handleTextUpdate, isDeleting ? 40 : 80);
      }
    };

    timer = setTimeout(handleTextUpdate, 70);

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, argumentIndex, allArguments]);

  useEffect(() => {
    if (getPreferredLanguage() !== "fr") {
      setPrice("50$");
      setPriceText(" Per Month For");
      setSansEngagement("No Commitment, initial price to negotiate");
    }
  }, []);

  return (
    <section className="Prix">
      <div className="prix carton">
        <div className="wrapper">
          <div className="textSide">
            <h1 className="text">
              <span className="titre">
                <span className="em">{price}</span> {priceText}
              </span>
            </h1>
            <div className="switching_text_wrapper">
              <h2>
                {displayedText}
                <span className="bar"></span>
              </h2>
            </div>
            <h1 className="text sans_engagement">{sansEngagement}</h1>
          </div>
          <div className="image_wrapper">
            <div className="image_size">
              <div className="zoink_effect fading">
                <img src="/img/Green.webp" alt="Prix" />
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="down_wrapper">
            <button className="arrow" onClick={handleClick}></button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Prix;
