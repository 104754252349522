import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51PXkRy2KrBIPINoSjroR5c4e75bDgk2DLUnN9vJYdqJpoeO9sITWMQgpEUqHfGZt6rq2lqy96YAygG48rewwCMwI00lm88769E"
);

const PaymentPage = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default PaymentPage;
