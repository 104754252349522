import React, { useEffect, useRef, useState } from "react";
import "./App.css";

import Croissance from "./Components/Croissance";
import Simplicite from "./Components/Simplicité";
import Succes from "./Components/Succès";
import Prix from "./Components/Prix";
import Formulaire from "./Components/Formulaire";
import AnimationPanel from "./Components/AnimationPanel";

const Desktop = () => {
  const wrapperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isScrolling = useRef(false);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 2800);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    const handleScroll = (event) => {
      event.preventDefault();
      if (isScrolling.current) return;

      isScrolling.current = true;
      const sections = wrapper.querySelectorAll("section");
      const numSections = sections.length;

      if (event.deltaY > 0) {
        setCurrentIndex((prevIndex) =>
          Math.min(prevIndex + 1, numSections - 1)
        );
      } else {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      }

      setTimeout(() => {
        isScrolling.current = false;
      }, 800);
    };

    wrapper.addEventListener("wheel", handleScroll);

    return () => {
      wrapper.removeEventListener("wheel", handleScroll);
    };
  }, [animation]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const sections = wrapperRef.current.querySelectorAll("section");
    if (currentIndex >= 0 && currentIndex < sections.length) {
      sections[currentIndex].scrollIntoView({ behavior: "smooth" });
    }
  }, [currentIndex]);

  const scrollToSection = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>
      {!animation && (
        <div>
          <AnimationPanel />
        </div>
      )}
      {animation && (
        <div className="Main_Wrapper" ref={wrapperRef}>
          <Simplicite scrollToSection={scrollToSection} currentIndex={0} />
          <Croissance scrollToSection={scrollToSection} currentIndex={1} />
          <Succes scrollToSection={scrollToSection} currentIndex={2} />
          <Prix scrollToSection={scrollToSection} currentIndex={3} />
          <Formulaire intoView={currentIndex === 4} />
        </div>
      )}
    </div>
  );
};

export default Desktop;
