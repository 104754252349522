import React, { useEffect, useState } from "react";
import "../css/Carton.css";
import "../css/Simplicité.css";
import getPreferredLanguage from "../utils/getPrefferedLanguage";

const Simplicite = ({ scrollToSection, currentIndex, isMobile = false }) => {
  const [titre, setTitre] = useState("Site Web Clé en Main");
  const [emFading, setEmFading] = useState(
    "Nous vous offrons une solution clé en main pour facilement mettre en valeur votre entreprise"
  );
  const [fading, setFading] = useState(
    "Optez pour notre solution clé en main pour la création de  votre site web, facile et rapide à mettre en place. Nous nous chargeons de tous les aspects techniques et créatifs afin de vous garantir un site web de haute qualité, le tout sans aucun effort requis de votre part."
  );

  const handleClick = () => {
    scrollToSection(currentIndex + 1);
  };

  useEffect(() => {
    if (getPreferredLanguage() !== "fr") {
      setTitre("TURNKEY WEBSITE SOLUTION");
      setEmFading(
        "We offer you a complete solution to easily showcase your work"
      );
      setFading(
        "Opt for our solution to create your website. Quick and easy to set up, we'll take care of all the technical and creative aspects to guarantee you a high-quality website, with no effort required on your part."
      );
    }
  }, []);

  return (
    <section className="Simplicite">
      <div className="simplicite carton">
        {!isMobile && (
          <>
            <div className="wrapper">
              <div className="textSide">
                <h1 className="text">
                  <span className="titre">{titre}</span>
                </h1>
                <br />
                <span className="em fading">{emFading}</span>
                <br />
                <span className="fading">{fading}</span>
              </div>
              <div className="image_wrapper">
                <div className="image_size">
                  <div className="zoink_effect fading">
                    <img src="/img/Red.webp" alt="Simplicité" />
                  </div>
                </div>
              </div>
            </div>
            <div className="down_wrapper">
              <button className="arrow" onClick={handleClick}></button>
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div className="wrapper">
              <div className="textSide">
                <h1 className="text">
                  <span className="titre">{titre}</span>
                </h1>
                <br />
                <span className="em fading">{emFading}</span>
                <br />
                <span className="fading">{fading}</span>
              </div>
              <div className="image_wrapper">
                <div className="image_size">
                  <div className="zoink_effect fading">
                    <img src="/img/Red.webp" alt="Simplicité" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Simplicite;
