import React, { useEffect } from "react";

const CheckoutForm = () => {
  useEffect(() => {
    // Function to get query parameter value
    function getQueryParameter(name) {
      let params = new URLSearchParams(window.location.search);
      return params.get(name);
    }

    let closerId = getQueryParameter("closer_id");
    if (closerId) {
      sessionStorage.setItem("closer_id", closerId);
    }

    // Attach an event listener to the stripe-pricing-table for custom event
    const pricingTable = document.querySelector("stripe-pricing-table");
    pricingTable.addEventListener("checkout", async (event) => {
      const sessionId = await createCheckoutSession();
      window.location.href = `/checkout?session_id=${sessionId}`;
    });
  }, []);

  const createCheckoutSession = async () => {
    const response = await fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        closer_id: sessionStorage.getItem("closer_id"),
      }),
    });

    const session = await response.json();
    return session.id;
  };

  const publishableKey =
    "pk_live_51PXkRy2KrBIPINoSKw6rcqarPGfor8s3GHasxqdE2qfpa0zFnvAHlg61TGjkZEZrYyBNNPZN0kr6cZtuA1qVDxC8004KkIFE3q";
  const pricingTableId = "prctbl_1PiRHj2KrBIPINoSF2rQZuZa";

  return (
    <div>
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
      ></stripe-pricing-table>
    </div>
  );
};

export default CheckoutForm;
