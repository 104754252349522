import { React, useEffect, useState } from "react";
import "../css/Carton.css";
import "../css/Succès.css";
import getPreferredLanguage from "../utils/getPrefferedLanguage";

const Succes = ({ scrollToSection, currentIndex, isMobile = false }) => {
  const [titre, setTitre] = useState("Au Service de votre succès");
  const [emFading, setEmFading] = useState(
    "Notre équipe de professionnels qualifiés créons des sites web réalisés sur-mesure"
  );
  const [fading, setFading] = useState(
    "Nous nous occupons de la création graphique de votre site, du webdesign de l'ergonomie en passant par la rédaction du contenu pour vous proposez un site personnalisé qui vous ressemble. Nous mettons tout en œuvre pour que chaque élément reflète parfaitement votre marque et vos besoins."
  );

  useEffect(() => {
    if (getPreferredLanguage() !== "fr") {
      setTitre("SERVING YOUR SUCCESS");
      setEmFading(
        "Our team of qualified professionals create custom-made websites for you."
      );
      setFading(
        "We take care of the graphic design of your site, from web design and ergonomics to content writing, we offer you a personalized site that reflects your personality. We make every effort to ensure that every element perfectly reflects your brand and your needs"
      );
    }
  }, []);

  const handleClick = () => {
    scrollToSection(currentIndex + 1);
  };
  return (
    <section className="Succes">
      <div className="succes carton">
        <div className="wrapper">
          <div className="textSide">
            <h1 className="text">
              <span className="titre">{titre}</span>
            </h1>

            <br />
            <span className="em fading">{emFading}</span>
            <br />
            <span className="fading">{fading}</span>
          </div>
          <div className="image_wrapper">
            <div className="image_size">
              <div className="zoink_effect fading">
                <img src="/img/Blue.webp" alt="Succès" />
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="down_wrapper">
            <button className="arrow" onClick={handleClick}></button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Succes;
