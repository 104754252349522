import "../css/Unsubscribe.css";

const Unsubscribe = () => {
  return (
    <>
      <div className="message_box_wrapper">
        <div className="message-box">
          Merci de vous être désabonné, bonne journée.
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
